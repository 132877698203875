import React from 'react';
import { FallbackProfile, MobilityBasicProfile } from '@finn-no/profilebox';
import { MobilityExtendedProfile } from '@finn-no/extended-profilebox';
import './styles.css';
import { MobilityHydratationData } from '../common/types.js';

const MobilityApp = ({
    adId,
    companyProfile,
    extendedProfileMobility,
    deviceType,
}: MobilityHydratationData): React.ReactElement => {
    if (extendedProfileMobility) {
        return <MobilityExtendedProfile {...extendedProfileMobility} />;
    } else {
        switch (companyProfile.tag) {
            case 'Mobility':
                return (
                    <MobilityBasicProfile
                        adId={adId}
                        deviceType={deviceType}
                        bpm={companyProfile}
                    />
                );
            default:
                return (
                    <FallbackProfile msg="Noe feilet dessverre i forsøket på å vise kontaktinformasjonen på denne annonsen" />
                );
        }
    }
};

export default MobilityApp;
