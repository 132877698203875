import React from 'react';
import { FallbackProfile, RealEstateBasicProfile } from '@finn-no/profilebox';
import { RealEstateExtendedProfile } from '@finn-no/extended-profilebox';
import './styles.css';
import { RealestateHydratationData } from '../common/types.js';

const RealestateApp = ({
    adId,
    companyProfile,
    extendedProfileRealEstate,
    deviceType,
}: RealestateHydratationData): React.ReactElement => {
    if (extendedProfileRealEstate) {
        return <RealEstateExtendedProfile {...extendedProfileRealEstate} />;
    } else {
        switch (companyProfile.tag) {
            case 'RealEstate':
                return (
                    <RealEstateBasicProfile
                        adId={adId}
                        deviceType={deviceType}
                        bpre={companyProfile}
                    />
                );
            default:
                return (
                    <FallbackProfile msg="Noe feilet dessverre i forsøket på å vise kontaktinformasjonen på denne annonsen" />
                );
        }
    }
};

export default RealestateApp;
